import { createSlice } from '@reduxjs/toolkit';

import { buildQueryStringFromObj } from '@/src/utils/buildQueryObj';

const initialState = {
  customers: [],
  customer: {},
  creditCard: [],
  address: [],
  pets: [],
  count: 0,
  query: {
    page: 1,
    perPage: 20,
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  metadata: {},
  selectedFilters: false,
  isLoading: false
};

const customersSlice = createSlice({
  name: '@customer',
  initialState,
  reducers: {
    setCustomers(prevState, action) {
      const state = prevState;
      state.customers = action.payload.customers;
      state.count = action.payload.count;
      state.query = {
        page: Number(action.payload.query.page),
        perPage: action.payload.query.perPage || state.query.perPage,
        search: action.payload.query.search || '',
        sort: action.payload.query.sort || '',
        filters: action.payload.query.filters || {},
        url: `?page=${action.payload.query.page || '1'}&per_page=${action
          .payload.query.perPage || state.query.perPage}${
          action.payload.query.search !== ''
            ? `&q=${action.payload.query.search}`
            : ''
        }${
          action.payload.query.sort !== ''
            ? `&sort${action.payload.query.sort}`
            : ''
        }${
          action.payload.query.filters
            ? buildQueryStringFromObj(action.payload.query.filters)
            : ''
        }`
      };
      state.isLoading = false;
      return state;
    },
    setCustomer(prevState, action) {
      const state = prevState;
      state.customer = action.payload.customer;
      if (action.payload.metadata) {
        state.metadata = action.payload.metadata;
      }
      return state;
    },
    setCustomerWallet(prevState, action) {
      const state = prevState;
      state.customer.attributes = {
        ...prevState.customer.attributes,
        wallet_flag: action.payload.attributes.wallet_flag
      };
      return state;
    },
    setIsLoading(prevState, action) {
      const state = prevState;
      state.isLoading = action.payload;
      return state;
    },
    setSearchTerm(prevState, action) {
      const state = prevState;
      state.query.search = action.payload;
      return state;
    },
    setPagination(prevState, action) {
      const state = prevState;
      state.query.page = action.payload;
      return state;
    },
    setSort(prevState, action) {
      const state = prevState;
      state.query.sort = action.payload;
      return state;
    },
    setFilter(prevState, action) {
      const state = prevState;
      state.query.filters = {
        ...state.query.filters,
        ...action.payload
      };
      return state;
    },
    setSelectedFilters(prevState, action) {
      const state = prevState;
      state.selectedFilters = action.payload;
      return state;
    },
    setCreditCard(prevState, action) {
      const state = prevState;
      state.creditCard = action.payload;
      return state;
    },
    setAddress(prevState, action) {
      const state = prevState;
      state.address = action.payload;
      return state;
    },
    setPets(prevState, action) {
      const state = prevState;
      state.pets = action.payload;
      return state;
    },
    getCustomers(state) {
      return state;
    },
    getCustomer(state) {
      return state;
    },
    getCustomerAddresses(state) {
      return state;
    },
    getCustomerCreditCards(state) {
      return state;
    },
    goToCustomers(state) {
      return state;
    },
    getEditCustomers(state) {
      return state;
    },
    getCustomerWalletTransactions(state) {
      return state;
    },
    editCustomerWallet(state) {
      return state;
    },
    logoutCustomer(state) {
      return state;
    },
    deactivateCustomer(state) {
      return state;
    },
    activateCustomer(state) {
      return state;
    },
    banishReferralCustomer(state) {
      return state;
    },
    activateCustomerOnReferral(state) {
      return state;
    },
    resetCustomerPassword(state) {
      return state;
    },
    exportCSV(state) {
      return state;
    },
    removeCustomer(state) {
      return state;
    }
  }
});

export const {
  setCustomers,
  setCustomer,
  setCustomerWallet,
  setIsLoading,
  setSearchTerm,
  setPagination,
  setSort,
  setFilter,
  setSelectedFilters,
  setHubs,
  setCreditCard,
  setAddress,
  setPets,
  getHubs,
  getCustomers,
  getCustomer,
  getCustomerAddresses,
  getCustomerCreditCards,
  goToCustomers,
  getEditCustomers,
  getCustomerWalletTransactions,
  editCustomerWallet,
  logoutCustomer,
  deactivateCustomer,
  banishReferralCustomer,
  activateCustomer,
  activateCustomerOnReferral,
  resetCustomerPassword,
  exportCSV,
  removeCustomer
} = customersSlice.actions;

export default customersSlice.reducer;
