import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Checkbox, Card } from 'antd';
import PropTypes from 'prop-types';

import { getPermissionsList } from '@/src/store/modules/users/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

const CheckboxGroup = Checkbox.Group;

const Permissions = props => {
  const {
    isBanned,
    selectedPermissions,
    setSelectedPermissions,
    disabled,
    kind,
    setPermissionsVisible
  } = props;

  const [modulesList, setModulesList] = useState([]);

  const { user, userPermissions, permissionsList } = useSelector(
    state => state.users
  );

  const { editPermission } = usePermissions();
  const hubsInvoicePermission = editPermission(modules.DISPATCH_HUBS_INVOICE);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPermissionsList());
  }, [dispatch]);

  useEffect(() => {
    const newUser = window.location.pathname.includes('/usuarios/novo');
    if (kind === 'stockist' && (newUser || user.kind !== 'stockist')) {
      const permissions = permissionsList
        .filter(
          item =>
            (item.fullName.includes('dispatch.orders') &&
              !item.fullName.includes('view')) ||
            item.fullName.includes('dispatch.deliveries') ||
            item.fullName.includes('dispatch.embarks') ||
            item.fullName === 'auth.users.manage' ||
            item.fullName.includes('auth.users.manage_deliverer') ||
            item.fullName.includes('auth.users.manage_stockist')
        )
        .map(item => `${item.fullName}/${item.id}`);
      setSelectedPermissions(permissions);
    } else if (kind === 'deliverer') {
      setSelectedPermissions([]);
    } else if (kind === 'tv') {
      const permission = permissionsList
        .filter(
          item =>
            item.fullName.includes('dispatch.orders.view') ||
            item.fullName === 'dispatch.orders.manage'
        )
        .map(item => `${item.fullName}/${item.id}`);
      setSelectedPermissions(permission);
    } else if (userPermissions) {
      const permissions = userPermissions.map(
        item => `${item.attributes.full_name}/${item.id}`
      );
      setSelectedPermissions(permissions);
    } else {
      setSelectedPermissions([]);
    }
    // eslint-disable-next-line
  }, [kind, userPermissions]);

  useEffect(() => {
    const modulesArr = modules.data
      .filter(item => {
        if (!item.showOnly) return true;
        return item.showOnly === process.env.REACT_APP_STORE;
      })
      .map(module => {
        const permission = permissionsList.find(
          item => item.fullName === module.permission
        );
        const moduleObj = {
          ...module,
          id: permission?.id
        };
        return moduleObj;
      });
    setModulesList(modulesArr);
    if (setPermissionsVisible) setPermissionsVisible(modulesArr);
    // eslint-disable-next-line
  }, [permissionsList]);

  const onSelectPermissions = value => {
    let permissions = value;
    if (!value.find(item => item.includes('clients.manage/'))) {
      permissions = permissions.filter(
        item =>
          !item.includes('clients.manage_credits') &&
          !item.includes('customers_wallet.manage') &&
          !item.includes('clients.manage_customers')
      );
    }
    if (!value.find(item => item.includes('clients.view/'))) {
      permissions = permissions.filter(
        item =>
          !item.includes('clients.manage/') &&
          !item.includes('clients.manage_credits') &&
          !item.includes('customers_wallet.manage') &&
          !item.includes('clients.manage_customers')
      );
    }
    if (!value.find(item => item.includes('subscriptions.view'))) {
      permissions = permissions.filter(
        item => !item.includes('subscriptions.manage')
      );
    }
    if (!value.find(item => item.includes('catalog.manage/'))) {
      permissions = permissions.filter(
        item =>
          !item.includes('catalog.manage_skus_petz') &&
          !item.includes('custom_fields.manage')
      );
    }
    if (!value.find(item => item.includes('dispatch.hubs.manage/'))) {
      permissions = permissions.filter(
        item => !item.includes('dispatch.hubs.manage_embark_require_invoice')
      );
    }
    if (!value.find(item => item.includes('dispatch.orders.manage/'))) {
      permissions = permissions.filter(
        item =>
          !item.includes('dispatch.orders.manage_pick_and_pack') &&
          !item.includes('dispatch.orders.view')
      );
    }
    if (!value.find(item => item.includes('auth.users.manage/'))) {
      permissions = permissions.filter(
        item =>
          !item.includes('auth.users.manage_deliverer') &&
          !item.includes('auth.users.manage_stockist') &&
          !item.includes('auth.users.manage_admin')
      );
    }
    setSelectedPermissions(permissions);
  };

  const checkForIndex = name => {
    // Clientes
    if (
      name.includes('clients.manage_credits') ||
      name.includes('customers_wallet.manage') ||
      name.includes('clients.manage_customers')
    ) {
      return !selectedPermissions.find(item =>
        item.includes('clients.manage/')
      );
    }
    if (name === 'admin_storefront.clients.manage') {
      return !selectedPermissions.find(item => item.includes('clients.view/'));
    }

    // Assinaturas
    if (name.includes('subscriptions.manage')) {
      return !selectedPermissions.find(item =>
        item.includes('subscriptions.view/')
      );
    }

    // Catálogo
    if (
      name.includes('catalog.manage_skus_petz') ||
      name.includes('custom_fields.manage')
    ) {
      return !selectedPermissions.find(item =>
        item.includes('catalog.manage/')
      );
    }

    // Hubs
    if (name === 'dispatch.hubs.manage_embark_require_invoice') {
      return (
        kind === 'deliverer' ||
        kind === 'stockist' ||
        kind === 'tv' ||
        !selectedPermissions.find(item =>
          item.includes('dispatch.hubs.manage/')
        ) ||
        !hubsInvoicePermission
      );
    }
    if (name.includes('dispatch.hubs.manage')) {
      return kind === 'tv';
    }

    // Expedição
    if (name === 'dispatch.orders.manage_pick_and_pack') {
      return (
        kind === 'deliverer' ||
        kind === 'tv' ||
        !selectedPermissions.find(item =>
          item.includes('dispatch.orders.manage/')
        )
      );
    }
    if (name.includes('dispatch.orders.view')) {
      return (
        kind !== 'tv' ||
        !selectedPermissions.find(item =>
          item.includes('dispatch.orders.manage/')
        )
      );
    }
    if (name === 'dispatch.orders.manage') {
      return kind === 'deliverer';
    }

    // Usuários
    if (
      name.includes('auth.users.manage_deliverer') ||
      name.includes('auth.users.manage_stockist')
    ) {
      return (
        kind === 'deliverer' ||
        kind === 'tv' ||
        !selectedPermissions.find(item => item.includes('auth.users.manage/'))
      );
    }
    if (name.includes('auth.users.manage_admin')) {
      return (
        kind === 'deliverer' ||
        kind === 'stockist' ||
        kind === 'tv' ||
        !selectedPermissions.find(item => item.includes('auth.users.manage/'))
      );
    }

    // Embarques, Entregadores, Usuários
    if (
      name === 'dispatch.deliveries.manage' ||
      name === 'dispatch.embarks.manage' ||
      name === 'auth.users.manage'
    ) {
      return kind === 'deliverer' || kind === 'tv';
    }

    // else
    return kind !== 'office';
  };

  return (
    <Card
      className="no-shadow"
      style={{ marginLeft: '7px' }}
      title="Permissões"
    >
      <p>Defina as permissões do usuário para navegar no Portal</p>
      <Row style={{ marginLeft: 20, marginTop: 12 }}>
        <Col span={12}>
          <CheckboxGroup
            onChange={list => onSelectPermissions(list)}
            defaultValue={selectedPermissions}
            value={selectedPermissions}
            disabled={isBanned || !kind}
            style={{ width: '100%' }}
          >
            {modulesList.map(item => {
              return (
                <Row style={{ marginBottom: 12 }} key={item.permission}>
                  <Checkbox
                    disabled={disabled || checkForIndex(item.permission)}
                    value={`${item.permission}/${item.id}`}
                    style={item.submodule ? { marginLeft: 20 } : {}}
                  >
                    {item.name}
                  </Checkbox>
                </Row>
              );
            })}
          </CheckboxGroup>
        </Col>
      </Row>
    </Card>
  );
};

Permissions.defaultProps = {
  isBanned: false,
  disabled: false,
  setPermissionsVisible: () => {},
  kind: ''
};

Permissions.propTypes = {
  isBanned: PropTypes.bool,
  disabled: PropTypes.bool,
  setSelectedPermissions: PropTypes.func.isRequired,
  setPermissionsVisible: PropTypes.func,
  selectedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  kind: PropTypes.string
};

export default Permissions;
