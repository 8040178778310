/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import {
  Row,
  Col,
  Form,
  Input,
  Icon,
  Select,
  Button,
  Card,
  Modal,
  Tooltip,
  Avatar,
  Breadcrumb,
  message,
  Upload,
  Menu,
  Dropdown,
  notification,
  Switch,
  Table
} from 'antd';
import PropTypes from 'prop-types';

import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import Searchbar from '@/src/components/Searchbar/Searchbar';
import Tables from '@/src/components/Tables';
import UploadImage from '@/src/components/UploadImage';
import ModalCSV from '@/src/components/ModalCSV';

import {
  setAssemblings,
  getAssembling,
  // getAssemblingProducts,
  updateAssembling,
  exportCSVAssemblingProducts,
  importCSVAssemblingProducts,
  archiveAssembling,
  unarchiveAssembling
} from '@/src/store/modules/assemblings/slice';
import dragIcon from '@/src/assets/images/drag-n-drop.svg';

import { getProducts } from '@/src/store/modules/products/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import './editAssembling.scss';

const { Option } = Select;

const RNDContext = createDndContext(HTML5Backend);

const type = 'DragableBodyRow';

const DragableBodyRow = ({
  // eslint-disable-next-line react/prop-types
  index,
  // eslint-disable-next-line react/prop-types
  moveRow,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

const EditAssembling = props => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;

  const { editPermission } = usePermissions();
  const catalogPermission = editPermission(modules.CATALOG);

  const { id } = useParams();

  const [edit, setEdit] = useState(false);
  const [isNoIndex, setIsNoIndex] = useState(false);
  const [showModalCSV, setShowModalCSV] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchSelected, setSearchSelected] = useState('');
  const [modalSelectedProducts, setModalSelectedProducts] = useState([]);
  const [
    modalSelectedProductsDetails,
    setModalSelectedProductsDetails
  ] = useState([]);
  const [editDrag, setEditDrag] = useState(false);
  // const [editDragGreaterThan25, setEditDragGreaterThan25] = useState(false);
  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsToAdd, setProductsToAdd] = useState([]);
  const [CSVFile, setCSVFile] = useState([]);
  const [CSVFileFail, setCSVFileFail] = useState(true);

  const dispatch = useDispatch();

  const { assembling, assemblingProducts, isLoading } = useSelector(
    state => state.assemblings
  );

  const {
    products: productsList,
    isLoading: isLoadingProducts,
    query,
    count
  } = useSelector(state => state.products);

  const searchSelectedRef = useRef();

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchSelectedRef}
          placeholder="Nome do produto"
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchSelected(e.target.value || '');
          }}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={confirm}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button
          id="assembling-products-clear-filter"
          onClick={clearFilters}
          size="small"
          style={{ width: 90 }}
        >
          Limpar
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <div
        style={{
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          color: filtered ? '#33d6ef' : '#333333'
        }}
      >
        <span style={{ fontSize: 14, padding: '0 8px' }}>
          {filtered ? searchSelected : 'BUSCAR'}
        </span>
        <Icon
          type={filtered ? 'close' : 'search'}
          style={{
            fontSize: filtered ? 16 : 18,
            paddingRight: 8
          }}
          onClick={e => {
            if (filtered) {
              e.stopPropagation();
              const clearBtn = document.getElementById(
                'assembling-products-clear-filter'
              );
              if (clearBtn) clearBtn.click();
            }
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchSelectedRef.current.select());
      }
    }
  });

  const productsCol = [
    {
      dataIndex: 'image',
      render: record => (
        <Avatar
          style={{ display: 'block', margin: '0 auto' }}
          shape="square"
          size={60}
          src={record}
        />
      ),
      width: 88
    },
    {
      dataIndex: 'name',
      colSpan: 2,
      ...getColumnSearchProps('name')
    }
  ];
  if (editDrag && catalogPermission) {
    const sortCol = {
      dataIndex: 'sort',
      width: 60,
      align: 'center',
      colSpan: 0,
      render: () =>
        editDrag ? <img src={dragIcon} alt="" style={{ fontSize: 20 }} /> : null
    };
    productsCol.push(sortCol);
  }

  const modalProductsCol = [
    {
      title: 'Imagem',
      dataIndex: 'image',
      render: (text, record) =>
        record.selected ? (
          <Tooltip title="Esse produto já foi adicionado">
            <Avatar
              shape="square"
              size={60}
              src={text}
              icon="camera"
              className="img-placeholder"
              style={{ display: 'block', margin: '0 auto' }}
            />
          </Tooltip>
        ) : (
          <Avatar
            shape="square"
            size={60}
            src={text}
            icon="camera"
            className="img-placeholder"
            style={{ display: 'block', margin: '0 auto' }}
          />
        ),
      width: 88
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      render: (text, record) =>
        record.selected ? (
          <Tooltip title="Esse produto já foi adicionado">
            <p>{text}</p>
          </Tooltip>
        ) : (
          <p>{text}</p>
        )
    }
  ];

  useEffect(() => {
    dispatch(getAssembling(id));
    // dispatch(getAssemblingProducts(id))
    dispatch(
      setAssemblings({
        assemblings: [],
        query: {},
        count: 0
      })
    );
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const arr = productsList.map(item => ({
      ...item.attributes,
      selected: !!productsToAdd.find(
        product => Number(product.id) === Number(item.id)
      )
    }));
    setProducts(arr);
    // eslint-disable-next-line
  }, [productsList]);

  useEffect(() => {
    if (assembling.image) setUploadImage(assembling.image);
    setIsNoIndex(assembling?.no_index);
  }, [assembling]);

  useEffect(() => {
    const arr = assemblingProducts.map(item => ({
      ...item.attributes
      // selected: true
    }));
    // setModalSelectedProducts(arr.map(item => item.id));
    setProductsToAdd(arr);
  }, [assemblingProducts]);

  const handleShowProductsModal = () => {
    dispatch(getProducts({ query: { page: 1, perPage: 20 } }));
    setShowProductsModal(true);
  };

  const handleCloseProductsModal = () => {
    setShowProductsModal(false);
    setModalSelectedProducts([]);
    setModalSelectedProductsDetails([]);
  };

  const rowSelectionModal = {
    selectedRowKeys: modalSelectedProducts,
    onChange: (selectedRowKeys, selectedRowObj) => {
      setModalSelectedProducts(selectedRowKeys);
      setModalSelectedProductsDetails(prev => {
        const newArr = [...prev, ...selectedRowObj];
        const ids = newArr.map(o => o.id);
        const filtered = newArr.filter(
          (item, index) => !ids.includes(item.id, index + 1)
        );
        return filtered;
      });
    },
    getCheckboxProps: record => ({
      disabled: record.selected
    })
  };

  const rowSelectionTable = {
    selectedRowKeys: selectedProducts,
    onChange: selectedRowKeys => setSelectedProducts(selectedRowKeys)
  };

  const handleAddProducts = () => {
    const items = modalSelectedProductsDetails.filter(item =>
      modalSelectedProducts.includes(item.id)
    );
    setProductsToAdd(prev => [...prev, ...items]);
    handleCloseProductsModal();
    notification.success({
      message: 'Sucesso',
      duration: 4,
      className: 'success',
      description:
        'Produto(s) adicionado(s) com sucesso, clique em "Salvar" para confirmar a alteração'
    });
  };

  const handleRemoveProducts = () => {
    setProductsToAdd(prev => {
      const arr = prev.filter(item => !selectedProducts.includes(item.id));
      return arr;
    });
    setProducts(prev => {
      const arr = prev.map(item => ({
        ...item,
        selected: !selectedProducts.includes(item.id)
      }));
      return arr;
    });
    setSelectedProducts([]);
    notification.success({
      message: 'Sucesso',
      duration: 4,
      className: 'success',
      description:
        'Produto(s) removido(s) com sucesso, clique em "Salvar" para confirmar a alteração'
    });
  };

  const saveAllConfirm = value => {
    Modal.confirm({
      title: 'SALVAR ALTERAÇÕES',
      okText: 'Confirmar',
      centered: true,
      content:
        'Tem certeza que deseja salvar as alterações? Todas as mudanças feitas serão espelhadas no app e no site.',
      onOk() {
        const image = uploadImage?.includes('data:image')
          ? uploadImage.replace(/data:image\/[\w]+;base64,/g, '')
          : '';
        const products_id = productsToAdd.map(product => product.id);
        const payload = {
          id,
          ...value,
          products_id,
          image,
          search_metadata: value.search_metadata || '',
          title: value.title || '',
          meta_title: value.meta_title || '',
          meta_description: value.meta_description || ''
        };
        dispatch(updateAssembling(payload));
      },
      onCancel() {}
    });
  };

  const checkIfHasImage = () => {
    setWithoutImage(uploadImage.length === 0);
    if (uploadImage.length === 0) return false;
    return true;
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err || !checkIfHasImage()) {
        return;
      }
      if (!err) {
        saveAllConfirm(values);
      }
    });
  };

  const handleGetProducts = useCallback(
    urlQuery => {
      dispatch(getProducts({ ...urlQuery }));
    },
    [dispatch]
  );

  const components = {
    body: {
      row: DragableBodyRow
    }
  };

  const manager = useRef(RNDContext);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = productsToAdd[dragIndex];
      setProductsToAdd(
        update(productsToAdd, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [productsToAdd]
  );

  const conditions = ['.csv', '.xlsx', '.xls'];
  const checkCSVType = file => conditions.some(el => file.name.includes(el));

  const beforeUpload = file => {
    checkCSVType(file);
    if (!checkCSVType(file)) {
      message.error('Apenas arquivos CSV são aceitos!', 5);
      setCSVFile([file]);
      setCSVFileFail(true);
      return false;
    }
    setCSVFileFail(false);
    setCSVFile([file]);
    return false;
  };

  const removeFile = () => {
    setTimeout(() => {
      setCSVFile([]);
    }, 400);
    setCSVFileFail(true);
  };

  const importCSV = useCallback(() => {
    dispatch(importCSVAssemblingProducts({ id, file: CSVFile[0] }));
    setShowModalCSV(false);
    // eslint-disable-next-line
  }, [dispatch, CSVFile]);

  const contentModalCSV = (
    <>
      <Row>
        <Col style={{ marginBottom: 10 }}>
          <p>
            Para começar a importação de produtos, sugerimos exportar a planilha
            modelo para editar e subir o arquivo novamente.
          </p>
        </Col>
        <Col
          style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
        >
          <Icon
            style={{ fontSize: '20px', marginRight: 5, color: '#2595D1' }}
            type="vertical-align-bottom"
          />
          <Button
            type="link"
            className="order-timeline"
            onClick={() =>
              dispatch(
                exportCSVAssemblingProducts({
                  id,
                  name: assembling.name
                })
              )
            }
          >
            <strong style={{ textDecoration: 'underline', color: '#2595D1' }}>
              Exportar planilha de produtos
            </strong>
          </Button>
        </Col>
        <Col>
          <strong>
            * Importante: sempre que uma planilha for atualizada, ela irá
            subscrever todas as informações existentes.
          </strong>
        </Col>
      </Row>
      <Row
        style={{
          borderBottom: '3px ##E2E2E2 solid',
          borderTop: '3px ##E2E2E2 solid'
        }}
      >
        <Col style={{ marginBottom: 25 }}>
          <p style={{ marginBottom: 0 }}>
            Caso já possua o arquivo de produtos que deseja atualizar, anexe a
            planilha abaixo.
          </p>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 25
          }}
        >
          <Upload
            className="upload-csv-file-halls"
            beforeUpload={beforeUpload}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onRemove={removeFile}
          >
            <Button
              // disabled={uploadButtonDisabled}
              className="ant-btn-secondary-copy"
            >
              Escolher Arquivo
            </Button>
          </Upload>
          {CSVFile.length === 0 ? (
            <p style={{ marginBottom: 0, marginLeft: 10 }}>
              Nenhum arquivo selecionado.
            </p>
          ) : null}
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    // console.log(productsToAdd);
    setEditDrag(productsToAdd.length > 1);
    // setEditDrag(productsToAdd.length > 1 && productsToAdd.length <= 25);
    // setEditDragGreaterThan25(productsToAdd.length > 25);
  }, [productsToAdd]);

  useEffect(() => {
    if (selectedProducts.length > 0) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedProducts]);

  const handleArchive = () => {
    dispatch(archiveAssembling({ id }));
  };

  const handleUnarchive = () => {
    dispatch(unarchiveAssembling({ id }));
  };

  const actions = (
    <Menu>
      {/* <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item> */}
      {assembling.archived_at ? (
        <Menu.Item key="2" onClick={handleUnarchive}>
          Desarquivar
        </Menu.Item>
      ) : (
        <Menu.Item key="1" onClick={handleArchive}>
          Arquivar
        </Menu.Item>
      )}
    </Menu>
  );

  const actionsAisle = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <ModalCSV
        titleModal="CONFIGURAR PRODUTOS POR PLANILHA"
        isVisible={showModalCSV}
        setIsVisibleModalCSV={() => setShowModalCSV(false)}
        components={contentModalCSV}
        buttonDisabled={CSVFileFail}
        CSVFileHandle={importCSV}
      />

      <Modal
        title="ADICIONAR PRODUTOS"
        visible={showProductsModal}
        className="user-logTable"
        width={900}
        // onOk={() => {}}
        onCancel={() => handleCloseProductsModal()}
        footer={[
          <Button
            key="submit"
            onClick={handleAddProducts}
            className="ant-btn ant-btn-primary"
          >
            ADICIONAR
          </Button>
        ]}
      >
        <Searchbar
          placeholder="Nome do produto"
          populateResult={handleGetProducts}
          query={query}
          value={query.search}
        />
        <p style={{ marginTop: 20, marginBottom: 4, fontWeight: 'bold' }}>
          {modalSelectedProducts.length} produto(s) selecionado(s)
        </p>
        <span>
          *Produtos já adicionados não podem ser selecionados novamente
        </span>
        <Tables
          data={products}
          rowKey={record => record.id}
          rowSelection={rowSelectionModal}
          rowClassName={record => record?.selected && 'disabled'}
          isLoading={isLoadingProducts}
          hasScroll
          columnsDefaults={modalProductsCol}
          query={query}
          count={count}
          visiblePaginationDown
          populateTables={handleGetProducts}
        />
      </Modal>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1 className="mb-10">Coleção {assembling.name}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/colecoes">Coleções</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar Coleção {assembling.name}</Breadcrumb.Item>
      </Breadcrumb>
      <section
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          padding: '16px',
          marginBottom: 60
        }}
      >
        <section>
          <Row gutter={8}>
            <Col>
              <Card
                className="no-shadow"
                title="DETALHES DA COLEÇÃO"
                loading={isLoading}
                extra={catalogPermission && actionsAisle}
              >
                <Form style={{ marginTop: 10 }}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <div style={{ width: '80%' }}>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                              Nome
                            </p>
                          </div>
                          <Form.Item>
                            {getFieldDecorator('name', {
                              initialValue: assembling.name,
                              rules: [
                                {
                                  required: true,
                                  message: 'Campo obrigatório'
                                }
                              ]
                            })(
                              <Input
                                placeholder="Digite o nome da coleção"
                                disabled={!catalogPermission}
                              />
                            )}
                          </Form.Item>
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                              Descrição
                            </p>
                          </div>
                          <Form.Item>
                            {getFieldDecorator('description', {
                              initialValue: assembling.description,
                              rules: [
                                {
                                  required: true,
                                  message: 'Campo obrigatório'
                                }
                              ]
                            })(
                              <Input
                                placeholder="Digite a descrição"
                                disabled={!catalogPermission}
                              />
                            )}
                          </Form.Item>
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                              Palavras-chave
                            </p>
                            <Tooltip
                              placement="right"
                              title="Palavras que facilitam encontrar produtos pelo cliente através da busca."
                            >
                              <Icon
                                style={{ marginLeft: 6 }}
                                type="info-circle"
                              />
                            </Tooltip>
                          </div>
                          <Form.Item>
                            {getFieldDecorator('search_metadata', {
                              initialValue: assembling.search_metadata,
                              rules: [
                                {
                                  required: true,
                                  message: 'Campo obrigatório'
                                }
                              ]
                            })(
                              <Input.TextArea
                                placeholder="Exemplo: black, gotham"
                                disabled={!catalogPermission}
                              />
                            )}
                          </Form.Item>
                        </Row>
                        <Row style={{ width: '50%' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                              Modo de visualização
                            </p>
                            {/* <Tooltip
                          placement="right"
                          title="Modo como a coleção será exibida"
                        >
                          <Icon style={{ marginLeft: 6 }} type="info-circle" />
                        </Tooltip> */}
                          </div>
                          <Form.Item>
                            {getFieldDecorator('display_mode', {
                              initialValue: assembling.display_mode,
                              rules: [
                                {
                                  required: true,
                                  message: 'Campo obrigatório'
                                }
                              ]
                            })(
                              <Select
                                placeholder="Selecione uma opção"
                                disabled={!catalogPermission}
                                // onSelect={() => setProductsDisabled(false)}
                              >
                                <Option key="grid" value="grid">
                                  Grid
                                </Option>
                                <Option key="lane" value="lane">
                                  Corredor
                                </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                              Slug
                            </p>
                          </div>
                          <Form.Item>
                            {getFieldDecorator('search_slug', {
                              initialValue: assembling.search_slug
                            })(
                              <Input
                                placeholder="URL da coleção na web"
                                disabled
                              />
                            )}
                          </Form.Item>
                        </Row>
                        <Row style={{ marginBottom: 16 }}>
                          <h2>SEO (WEB)</h2>
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                marginBottom: 0
                              }}
                            >
                              Title
                            </p>
                            <Tooltip
                              placement="right"
                              title="Título da vitrine exibido na página da coleção"
                            >
                              <Icon
                                style={{ marginLeft: 6 }}
                                type="info-circle"
                              />
                            </Tooltip>
                          </div>
                          <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator('title', {
                              initialValue: assembling.title
                            })(
                              <Input
                                placeholder="Título da vitrine"
                                disabled={!catalogPermission}
                              />
                            )}
                          </Form.Item>
                          <span style={{ display: 'block', marginBottom: 24 }}>
                            *Caso não preenchido, será o nome da coleção
                          </span>
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                marginBottom: 0
                              }}
                            >
                              Meta Title
                            </p>
                            <Tooltip
                              placement="right"
                              title="Título da vitrine exibido em ferramentas de busca"
                            >
                              <Icon
                                style={{ marginLeft: 6 }}
                                type="info-circle"
                              />
                            </Tooltip>
                          </div>
                          <Form.Item>
                            {getFieldDecorator('meta_title', {
                              initialValue: assembling.meta_title
                            })(
                              <Input
                                placeholder="Exemplo: Zee.Now - Nome da coleção"
                                disabled={!catalogPermission}
                              />
                            )}
                          </Form.Item>
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 'bold',
                                marginBottom: 0
                              }}
                            >
                              Meta Description
                            </p>
                            <Tooltip
                              placement="right"
                              title="Descrição da vitrine exibida em ferramentas de busca"
                            >
                              <Icon
                                style={{ marginLeft: 6 }}
                                type="info-circle"
                              />
                            </Tooltip>
                          </div>
                          <Form.Item>
                            {getFieldDecorator('meta_description', {
                              initialValue: assembling.meta_description
                            })(
                              <Input.TextArea
                                placeholder="Breve descrição da coleção"
                                disabled={!catalogPermission}
                              />
                            )}
                          </Form.Item>
                        </Row>
                        <Row>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: 4
                            }}
                          >
                            <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                              Não indexar
                            </p>
                            <Tooltip
                              placement="right"
                              title="Selecione caso coleção NÃO precise ser incluída no sitemap"
                            >
                              <Icon
                                style={{ marginLeft: 6 }}
                                type="info-circle"
                              />
                            </Tooltip>
                            <Form.Item
                              style={{ marginBottom: 0, marginLeft: 16 }}
                            >
                              {getFieldDecorator('no_index')(
                                <Switch
                                  checked={isNoIndex}
                                  onChange={value => setIsNoIndex(value)}
                                  disabled={!catalogPermission}
                                  checkedChildren={
                                    <Icon
                                      style={{ display: 'block' }}
                                      type="check"
                                    />
                                  }
                                  unCheckedChildren={
                                    <Icon
                                      style={{ display: 'block' }}
                                      type="close"
                                    />
                                  }
                                />
                              )}
                            </Form.Item>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div style={{ width: '80%' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 4
                          }}
                        >
                          <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                            Imagem
                          </p>
                        </div>
                        <UploadImage
                          setUploadImage={setUploadImage}
                          uploadImage={uploadImage}
                          withoutImage={withoutImage}
                          setWithoutImage={setWithoutImage}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
            <span style={{ margin: '8px 0', display: 'block' }} />
            <Col>
              <Card
                className="no-shadow"
                style={{ paddingTop: 16, minHeight: '48px' }}
              >
                <Row
                  type="flex"
                  justify="space-between"
                  style={{ marginBottom: 15 }}
                >
                  <Col>
                    <h2>PRODUTOS</h2>
                    <span>Selecione o(s) produto(s) para a coleção.</span>
                  </Col>
                  <Col>
                    <Button
                      className="ant-btn-secondary-copy"
                      disabled={!catalogPermission}
                      onClick={() => setShowModalCSV(true)}
                      style={{ marginRight: 20 }}
                    >
                      Produtos por planilha
                    </Button>
                    <Button
                      className="ant-btn-primary"
                      onClick={handleShowProductsModal}
                      disabled={!catalogPermission}
                    >
                      Adicionar produto
                    </Button>
                  </Col>
                </Row>
                {/* <Row style={{ marginBottom: 15 }}>
                  <Col>
                    <Searchbar
                      placeholder="Nome do produto"
                      populateResult={handleProductsSearch}
                      query={query}
                      // value={query.search}
                    />
                  </Col>
                </Row> */}
                {/* {editDragGreaterThan25 && (
                  <Row>
                    <Col
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: edit ? 10 : 0
                      }}
                    >
                      <Icon type="info-circle" />
                      <p
                        style={{
                          marginLeft: 5,
                          fontSize: 16,
                          marginBottom: 0,
                          fontWeight: 'bold'
                        }}
                      >
                        A ordernação dos produtos só é possível com máximo de 25
                        itens. Use a planilha para fazer a ordenação dos
                        produtos acima de 25 itens.
                      </p>
                    </Col>
                  </Row>
                )} */}
                {edit ? (
                  <Row type="flex" justify="start">
                    <Col span={12} className="delete-products">
                      <Col span={11}>
                        <p style={{ marginBottom: 0 }}>
                          {selectedProducts.length} produto(s) selecionado(s)
                        </p>
                      </Col>
                      <Col span={5}>
                        <Button
                          onClick={handleRemoveProducts}
                          disabled={!catalogPermission}
                        >
                          Excluir
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                ) : null}
                <DndProvider manager={manager.current.dragDropManager}>
                  <Table
                    style={{ marginTop: 24 }}
                    columns={productsCol}
                    rowKey={record => record.id}
                    dataSource={productsToAdd}
                    pagination={false}
                    className="ant-table-select-drag"
                    rowSelection={rowSelectionTable}
                    components={editDrag ? components : {}}
                    onRow={
                      editDrag
                        ? (record, index) => ({
                            index,
                            moveRow
                          })
                        : null
                    }
                  />
                </DndProvider>
              </Card>
            </Col>
          </Row>
        </section>
      </section>
      <footer className="footer-form-save">
        <Button
          className="ant-btn ant-btn-primary"
          onClick={onSubmit}
          style={{ left: '90%' }}
          disabled={productsToAdd.length <= 0 || !catalogPermission}
          loading={isLoading}
        >
          Salvar
        </Button>
      </footer>
    </>
  );
};

EditAssembling.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const EditAssemblingForm = Form.create({ name: 'EditAssembling' })(
  EditAssembling
);

export default EditAssemblingForm;
