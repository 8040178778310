import React, { useState /* useEffect */ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  Icon,
  Menu,
  Dropdown,
  Button,
  Input,
  Modal,
  Form,
  Tooltip
} from 'antd';
import PropTypes from 'prop-types';

import Audit from '@/src/components/Audit/index';

import formatCPF from '@/src/utils/formatCPF';
import handleCall from '@/src/utils/handleCallToCustomer';
import { formatDDIPhone } from '@/src/utils/formatPhone';
import getNumberFromString from '@/src/utils/getNumberFromString';
import makeCall from '@/src/utils/makeCallToCustomer';

import {
  editCustomerWallet,
  removeCustomer
} from '@/src/store/modules/customers/slice';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import { ZEEDOG_STORE } from '@/src/globals/constants';

const Details = props => {
  const {
    customer,
    form,
    id,
    loading,
    editCustomer,
    banishReferral,
    activateCustomerOnReferral,
    logoutCustomer,
    deactivateCustomer,
    activateCustomer,
    resetCustomerPassword
    // editPermission
  } = props;

  const { confirm } = Modal;

  const { getFieldDecorator, validateFields } = form;

  const [userEdit, setUserEdit] = useState(false);
  const [copy, setCopy] = useState('Copiar telefone');
  const [showAudit, setShowAudit] = useState(false);

  const { wallet } = useSelector(state => state.wallet);

  const dispatch = useDispatch();

  const { editPermission } = usePermissions();

  const customersPermission = editPermission(modules.CUSTOMERS_MANAGE);
  const removeCustomerPermission = editPermission(modules.REMOVE_CUSTOMER);
  const walletPermission = editPermission(modules.WALLET);

  // conditions to show menu options
  const removeCustomerOption =
    removeCustomerPermission &&
    !customer?.attributes?.email.startsWith('disabled-');
  const walletOption = walletPermission && wallet?.id;

  // const titleModalLogs = 'HISTÓRICO DE ATUALIZAÇÕES - CLIENTES';

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      /* take the phone_number to change to String and to
      phone format to send to back-end */
      const phoneNumberToString = values.phone_number.toString();
      const numberPhoneCustomer = formatDDIPhone(phoneNumberToString);

      // take the cpf to change to String
      const cpfNumberToString = values.cpf.toString();

      const value = {
        name: values.name,
        email: values.email,
        document: cpfNumberToString,
        phone_number: numberPhoneCustomer
      };
      editCustomer(value);
      setUserEdit(false);
    });
  };

  const showConfirmBanReferrals = type => {
    confirm({
      title: `${type === 'unban' ? 'REATIVAR' : 'BANIR'} DE INDICAÇÕES`,
      content: `Tem certeza que deseja ${
        type === 'unban' ? 'reativar' : 'banir'
      } o cliente de participar do programa de indicações da Zee.Now?`,
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'secondary'
      },
      onOk() {
        if (type === 'unban') activateCustomerOnReferral({ id });
        else banishReferral({ id });
        return false;
      }
    });
  };

  const showConfirmBanCustomer = type => {
    confirm({
      title: `${type === 'unban' ? 'REATIVAR' : 'BANIR'} CLIENTE`,
      content: `Tem certeza que deseja ${
        type === 'unban' ? 'reativar o cliente para' : 'banir o cliente de'
      } total acesso na Zee.Now?`,
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'secondary'
      },
      onOk() {
        if (type === 'unban') activateCustomer({ id });
        else deactivateCustomer({ id });
        return false;
      }
    });
  };

  const showConfirmReset = () => {
    confirm({
      title: `RESETAR SENHA DO CLIENTE ${customer?.attributes?.name.toUpperCase()}`,
      content: `Deseja realmente resetar a senha deste cliente? Ao confirmar, o cliente receberá um e-mail com as instruções para nova senha.`,
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'secondary'
      },
      onOk() {
        resetCustomerPassword();
      }
    });
  };

  const showConfirmLogout = () => {
    confirm({
      title: `DESLOGAR CLIENTE ${customer?.attributes?.name.toUpperCase()}`,
      content: `Deseja realmente deslogar este cliente? Ao confirmar, o cliente será deslogado do aplicativo em todos os dispositivos e em ZN Web`,
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'secondary'
      },
      onOk() {
        logoutCustomer();
      }
    });
  };

  const showConfirmRemove = () => {
    confirm({
      title: `EXCLUIR CLIENTE ${customer?.attributes?.name.toUpperCase()}`,
      content: `Deseja realmente excluir o cadastro deste cliente?`,
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'secondary'
      },
      onOk() {
        dispatch(removeCustomer({ id }));
      }
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => showConfirmLogout()} key="customer-logout">
        Deslogar cliente
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        onClick={
          customer?.attributes?.banned
            ? () => showConfirmBanCustomer('unban')
            : () => showConfirmBanCustomer('ban')
        }
        key="customer-ban"
      >
        {customer?.attributes?.banned ? 'Reativar' : 'Banir'} cliente
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        onClick={
          customer?.attributes?.banned_from_referral
            ? () => showConfirmBanReferrals('unban')
            : () => showConfirmBanReferrals('ban')
        }
        key="referral-ban"
      >
        {customer?.attributes?.banned_from_referral ? 'Reativar' : 'Banir'} de
        Indicações
      </Menu.Item>
      <Menu.Divider />
      {removeCustomerOption && (
        <Menu.Item onClick={showConfirmRemove} key="customer-removal">
          Excluir cadastro de cliente
        </Menu.Item>
      )}
      {removeCustomerOption && <Menu.Divider />}
      <Menu.Item
        disabled={ZEEDOG_STORE}
        onClick={showConfirmReset}
        key="customer-password-reset"
      >
        Resetar senha
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        onClick={userEdit ? () => setUserEdit(false) : () => setUserEdit(true)}
        key="customer-edit"
      >
        {userEdit ? 'Cancelar edição de ' : 'Editar '} cliente
      </Menu.Item>
      <Menu.Divider />
      {walletOption && (
        <Menu.Item
          onClick={() => {
            const value = {
              wallet_flag: !customer.attributes.wallet_flag
            };
            dispatch(editCustomerWallet({ value, id }));
          }}
          key="customer-wallet"
        >
          {customer?.attributes?.wallet_flag ? 'Desativar' : 'Ativar'} carteira
        </Menu.Item>
      )}
      {walletOption && <Menu.Divider />}
      <Menu.Item onClick={() => setShowAudit(true)} key="customer-audit">
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const customerActions = (
    <Dropdown overlay={menu} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const handlePhoneNumber = value => {
    const formatedPhone = formatDDIPhone(value.target.value);
    // eslint-disable-next-line no-param-reassign
    value.target.value = formatedPhone;
  };

  const formuserEdit = (
    <Row gutter={[8, 16]}>
      <h4>
        <strong>Nome</strong>
      </h4>
      <Form.Item>
        {getFieldDecorator('name', {
          initialValue: customer?.attributes?.name,
          rules: [
            {
              required: true,
              message: 'Deve ter no mínimo 2 caracteres',
              min: 2
            }
          ]
        })(<Input placeholder="Ex.: Fábio Almeida" />)}
      </Form.Item>
      <h4>
        <strong>CPF</strong>
      </h4>
      <Form.Item>
        {getFieldDecorator('cpf', {
          initialValue: customer?.attributes?.document,
          rules: [
            {
              required: true,
              message: 'Por favor, preencha o CPF'
            },
            {
              validator: (rule, value, cb) => value.toString().length < 12,
              message: 'Por favor, preencha o CPF no formato correto'
            }
          ]
        })(
          <Input
            placeholder="Ex.: 123.456.789-12"
            // formatter={event => formatCPF(event)}
            // parser={value => getNumberFromString(value)}
          />
        )}
      </Form.Item>
      <h4>
        <strong>E-mail</strong>
      </h4>
      <Form.Item>
        {getFieldDecorator('email', {
          initialValue: customer?.attributes?.email,
          rules: [
            {
              required: true,
              message: 'Por favor, preencha este campo'
            },
            {
              type: 'email',
              message: 'E-mail deve ser válido'
            }
          ]
        })(<Input placeholder="Ex.: zeedog@zeedog.com" />)}
      </Form.Item>
      <h4>
        <strong>Telefone</strong>
      </h4>
      <Form.Item>
        {getFieldDecorator('phone_number', {
          initialValue:
            customer?.attributes?.phone_number &&
            getNumberFromString(customer?.attributes?.phone_number),
          rules: [
            {
              required: true,
              message: 'Por favor, preencha este campo'
            },
            {
              validator: (rule, value, cb) => {
                const formattedValue = formatDDIPhone(value.toString());
                return formattedValue.length === 19;
              },
              message: 'Por favor, preencha o telefone no formato correto'
            }
          ]
        })(
          <Input
            placeholder="55 (21) 99999-9999"
            onChange={handlePhoneNumber}
            // formatter={event => formatDDIPhone(event)}
            // parser={value => getNumberFromString(value)}
          />
        )}
      </Form.Item>
      <Row gutter={10}>
        <Col span={10} offset={4}>
          <Button
            type="secondary"
            style={{ width: '100%' }}
            onClick={() => setUserEdit(false)}
          >
            CANCELAR
          </Button>
        </Col>
        <Col span={10}>
          <Button
            style={{ width: '100%' }}
            onClick={onSubmit}
            loading={loading}
          >
            SALVAR
          </Button>
        </Col>
      </Row>
    </Row>
  );

  const phoneCopy = () => {
    navigator.clipboard.writeText(customer?.attributes?.phone_number);
    setCopy('Telefone copiado');
  };

  return (
    <>
      <Audit
        id={id}
        title="CLIENTE"
        type="Customer"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Card
        className="no-shadow"
        title={<h2>Dados pessoais</h2>}
        extra={customersPermission && customerActions}
        loading={loading}
      >
        {!userEdit ? (
          <>
            <div>
              <h4>
                <strong>Nome</strong>
              </h4>
              <p>
                {customer?.attributes?.name
                  ? customer.attributes.name
                  : 'Sem nome'}
              </p>
            </div>
            <div>
              <h4>
                <strong>CPF</strong>
              </h4>
              <p>
                {customer?.attributes?.document
                  ? formatCPF(customer?.attributes?.document)
                  : 'Sem CPF'}
              </p>
            </div>
            <div style={{ marginBottom: '14px' }}>
              <h4>
                <strong>E-mail</strong>
              </h4>
              {customersPermission ? (
                <a
                  href={
                    customer?.attributes?.email
                      ? `mailto:${customer.attributes.email}`
                      : '#'
                  }
                  className="text-blue text-underline"
                >
                  {customer?.attributes?.email
                    ? customer.attributes.email
                    : 'Sem e-mail'}
                </a>
              ) : (
                <p>
                  {customer?.attributes?.email
                    ? customer.attributes.email
                    : 'Sem e-mail'}
                </p>
              )}
            </div>
            <div>
              <h4>
                <strong>Telefone</strong>
              </h4>
              {customer?.attributes?.phone_number && customersPermission ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    type="link"
                    onClick={() =>
                      handleCall({
                        name: customer?.attributes?.name,
                        phone_number: customer?.attributes?.phone_number,
                        cb: makeCall
                      })
                    }
                    className=" text-blue"
                    style={{ padding: 0 }}
                  >
                    {customer?.attributes?.phone_number}
                  </Button>
                  <Tooltip placement="rightTop" title={copy}>
                    <Button
                      type="link"
                      className="order-timeline"
                      onClick={phoneCopy}
                      style={{ marginLeft: '10px' }}
                    >
                      <Icon
                        type="copy"
                        key="phone_copy"
                        style={{ fontSize: '22px' }}
                      />
                    </Button>
                  </Tooltip>
                </div>
              ) : (
                <p>
                  {customer?.attributes?.phone_number ||
                    'Sem telefone cadastrado'}
                </p>
              )}
            </div>
            {/* <div>
            <h4>
              <strong>Genêro</strong>
            </h4>
            <p>
              {customer?.attributes?.gender
                ? customer?.attributes?.gender
                : 'Genêro indefinido'}
            </p>
          </div> */}
            {/* <div style={{ marginBottom: '5px' }}>
            <h4>
              <strong>Observações do Cliente</strong>
            </h4>
            <Form>
              <Form.Item>
                {getFieldDecorator('observation')(
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder="Deixe um comentário"
                  />
                )}
              </Form.Item>
            </Form>
          </div>
          <Row>
            <Col span={8} />
            <Col span={8} offset={8}>
              <Button style={{ width: '100%' }}>SALVAR</Button>
            </Col>
          </Row> */}
          </>
        ) : null}
        {userEdit ? <Form layout="vertical">{formuserEdit}</Form> : null}
      </Card>
    </>
  );
};

Details.defaultProps = {
  match: {
    params: {
      userId: ''
    }
  },
  loading: false
};
Details.propTypes = {
  editCustomer: PropTypes.func.isRequired,
  banishReferral: PropTypes.func.isRequired,
  activateCustomerOnReferral: PropTypes.func.isRequired,
  resetCustomerPassword: PropTypes.func.isRequired,
  logoutCustomer: PropTypes.func.isRequired,
  deactivateCustomer: PropTypes.func.isRequired,
  activateCustomer: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string
    }).isRequired
  }),
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    attributes: PropTypes.objectOf(PropTypes.any)
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const EditCustomerForm = Form.create({ name: 'edit_customer_details_form' })(
  Details
);

export default EditCustomerForm;
