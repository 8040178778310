import { ZEEDOG_STORE } from '../globals/constants';

const queryBuilder = (payload, endpoint, noPagination = false) => {
  if (!payload) return endpoint;
  const { query } = payload;
  const pageParam = noPagination ? '' : `&page=${query.page || 1}`;
  const perPageParam = noPagination ? '' : `&per_page=${query.perPage || 20}`;
  const daysAgoParam = query.daysAgo ? `&days_ago=${query.daysAgo}` : '';
  const searchTermParam = query.search ? `&q=${query.search}` : '';
  const sortTermParam = query.sort ? `&sort${query.sort}` : '';
  const hasFilters = query.filters;
  let filtersParam = '';
  if (hasFilters) {
    Object.keys(hasFilters).forEach(key => {
      if (
        key === 'by_hub' ||
        key === 'by_status' ||
        key === 'by_deliverer' ||
        key === 'by_property' ||
        key === 'by_categories'
      ) {
        if (
          ZEEDOG_STORE &&
          key === 'by_status' &&
          !Array.isArray(hasFilters[key])
        ) {
          filtersParam += `&filters[${key}][]=${hasFilters[key]}`;
        } else {
          hasFilters[key].forEach(id => {
            filtersParam += `&filters[${key}][]=${id}`;
          });
        }
      } else if (key === 'by_coupons') {
        hasFilters[key].forEach(id => {
          filtersParam += `&filters[${key}][]=${id}`;
        });
      } else if (key === 'pet_breed' || key === 'by_tag') {
        if (typeof hasFilters[key] === 'string') {
          const breedArray = hasFilters[key].split(',');
          breedArray.forEach(value => {
            filtersParam += `&filters[${key}][]=${value}`;
          });
        } else {
          hasFilters[key].forEach(value => {
            filtersParam += `&filters[${key}][]=${value}`;
          });
        }
      } else if (key === 'by_recommType' && hasFilters[key]) {
        filtersParam += `&type=${hasFilters[key]}`;
      } else {
        filtersParam += `&filters[${key}]=${hasFilters[key]}`;
      }
    });
  }
  const customQuery = [
    'price_table_id',
    'include[]',
    'price_table_ids',
    'sku_price_lte',
    'sku_price_gte',
    'hub_ids',
    'by_hub',
    'by_date'
  ];
  const hasCustomQuery = Object.keys(query).filter(item =>
    customQuery.includes(item)
  );
  let customParam = '';
  if (hasCustomQuery.length) {
    hasCustomQuery.forEach(item => {
      if (query[item] && item) {
        customParam += `&${item}=${query[item]}`;
      }
    });
  }
  const paramsUrl = `${endpoint}${pageParam}${perPageParam}${daysAgoParam}${searchTermParam}${sortTermParam}${filtersParam}${customParam}`;
  const getUrl = paramsUrl.replace('&', '?');
  return getUrl;
};

export const dispatchQueryBuilder = (payload, endpoint) => {
  if (!payload || !payload.query) return endpoint;

  const pageNumberParam = payload.query.page
    ? `&page[number]=${payload.query.page}`
    : '';
  const perPageParam = payload.query.perPage
    ? `&page[size]=${payload.query.perPage}`
    : '';
  const isDescSort = payload.query.sort?.includes('=desc') ? '-' : '';
  const sortParam = payload.query.sort
    ? `&sort=${isDescSort}${payload.query.sort.split(']')[0].replace('[', '')}`
    : '';

  const basicParams = pageNumberParam + perPageParam + sortParam;

  const hasFilters = payload.query.filters;
  let filtersParam = '';
  if (hasFilters?.length > 0) {
    hasFilters.forEach(({ key, type, value }) => {
      filtersParam += `&filter[${key}][${type}]=${value}`;
    });
  }

  const hasFields = payload.query.fields;
  let fieldsParam = '';
  if (hasFields?.length > 0) {
    hasFields.forEach(({ key, value }) => {
      fieldsParam += `&fields[${key}]=${value}`;
    });
  }

  const hasExtraFields = payload.query.extra_fields;
  let extraFieldsParam = '';
  if (hasExtraFields?.length > 0) {
    hasExtraFields.forEach(({ key, value }) => {
      extraFieldsParam += `&extra_fields[${key}]=${value}`;
    });
  }

  const hasStats = payload.query.stats;
  let statsParam = '';
  if (hasStats?.length > 0) {
    hasStats.forEach(({ key, value }) => {
      statsParam += `&stats[${key}]=${value}`;
    });
  }

  const includeParam = payload.query.include
    ? `&include=${payload.query.include}`
    : '';

  const getUrl = `${endpoint}${basicParams}${filtersParam}${fieldsParam}${extraFieldsParam}${includeParam}${statsParam}`;
  return getUrl.replace('&', '?');
};

export default queryBuilder;
