import { takeEvery, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { errorAlert, successAlert } from '@/src/utils/notifications';
import { notification } from 'antd';
import bugsnag from '@/src/services/bugsnag';

import {
  getCustomers as getCustomersRequest,
  editCustomer as editCustomerResquest,
  getCustomerWalletTransactions as getCustomerWalletTransactionsRequest,
  editCustomerWallet as editCustomerWalletRequest,
  getCustomer as getCustomerRequest,
  getCustomerAdresses as getCustomerAdressesRequest,
  getCustomerCreditCards as getCustomerCreditCardsRequest,
  deactivateCustomer as deactivateCustomerResquest,
  activateCustomer as activateCustomerResquest,
  removeCustomer as removeCustomerRequest,
  logoutCustomer as logoutCustomerRequest
} from '@/src/api/customers';

import { dispatchResetPassword as resetPasswordRequest } from '@/src/api/users';

import { exportCSVCustomer as exportCSVCustomerResquest } from '@/src/api/export-file';

import {
  setIsLoading,
  setCustomers,
  setCustomer,
  setCustomerWallet,
  setPets,
  getCustomers,
  getCustomer,
  getCustomerAddresses,
  getCustomerCreditCards,
  goToCustomers,
  getEditCustomers,
  getCustomerWalletTransactions,
  editCustomerWallet,
  logoutCustomer,
  deactivateCustomer,
  activateCustomer,
  setCreditCard,
  setAddress,
  resetCustomerPassword,
  exportCSV,
  removeCustomer
} from '@/src/store/modules/customers/slice';

import {
  setWallet,
  setWalletTransactions,
  setIsLoading as setIsLoadingWallet
} from '@/src/store/modules/wallet/slice';

import {
  getCustomersQueryUrlState,
  getRouterState
} from '@/src/store/selectors';

import { setTags } from '@/src/store/modules/tags/slice';

function* getCustomersSaga(params) {
  const { payload } = params;
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCustomersRequest, payload);
    const count = response.data.meta.count > 0 ? response.data.meta.count : 0;
    yield put(
      setCustomers({
        customers: response.data.data,
        count,
        query: payload.query
      })
    );
    const queryUrl = yield select(getCustomersQueryUrlState);
    const routerState = yield select(getRouterState);
    if (routerState.location.search !== queryUrl) {
      yield put(push(queryUrl));
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* getCustomerSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoading(true));
    const response = yield call(getCustomerRequest, payload);
    const metadata = response.data.meta;
    const wallet = response.data.included.filter(
      included => included.type === 'wallet'
    );
    const creditCard = response.data.included.filter(
      included => included.type === 'credit_card'
    );
    const tag = response.data.included.filter(
      included => included.type === 'tag'
    );
    const tags = tag.map(tagName => tagName.attributes.name);
    yield put(setTags(tags));
    yield put(setCreditCard(creditCard));
    const pets = response.data.included.filter(
      included => included.type === 'pet'
    );
    yield put(setPets(pets));
    yield put(setWallet(wallet[0] || {}));
    yield put(setCustomer({ customer: response.data.data, metadata }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

export function* getCustomerWalletTransactionsSaga(action) {
  const { payload } = action;
  try {
    yield put(setIsLoadingWallet(true));
    const response = yield call(getCustomerWalletTransactionsRequest, payload);
    yield put(
      setWalletTransactions({ ...response.data, page: payload.query.page })
    );
    yield put(setIsLoadingWallet(false));
  } catch (err) {
    bugsnag.notify(err);
    yield put(setIsLoadingWallet(false));
    notification.error({
      message: 'Erro',
      duration: 4,
      className: 'error',
      description: 'Não foi possível carregar o extrato da carteira do cliente.'
    });
  }
}

function* getCustomerAddressesSaga(action) {
  const { payload } = action;
  try {
    const addresses = yield call(getCustomerAdressesRequest, payload);
    yield put(setAddress(addresses.data.data));
  } catch (err) {
    bugsnag.notify(err);
    notification.error({
      message: 'Erro',
      duration: 4,
      className: 'error',
      description: 'Não foi possível carregar os endereços do cliente.'
    });
  }
}

function* getCustomerCreditCardsSaga(action) {
  const { payload } = action;
  try {
    const creditCards = yield call(getCustomerCreditCardsRequest, payload);
    yield put(setCreditCard(creditCards.data.data));
  } catch (err) {
    bugsnag.notify(err);
    notification.error({
      message: 'Erro',
      duration: 4,
      className: 'error',
      description: 'Não foi possível carregar os cartões de crédito do cliente.'
    });
  }
}

function* goToCustomerSaga(action) {
  const { payload } = action;
  yield put(push(`/pedidos/${payload.id}`));
}

function* editCustomerSaga(action) {
  const { payload } = action;
  try {
    yield setIsLoading(true);
    const response = yield call(editCustomerResquest, payload);
    const metadata = response.data.meta;
    yield put(setCustomer({ customer: response.data.data, metadata }));
    successAlert('Os dados do cliente foram atualizados.');
    yield setIsLoading(false);
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* editCustomerWalletSaga(action) {
  const { payload } = action;
  try {
    yield setIsLoading(true);
    const response = yield call(editCustomerWalletRequest, payload);
    yield put(setCustomerWallet(response.data.data));
    successAlert(
      `A carteira do cliente foi ${
        payload.value.wallet_flag ? 'ativada' : 'desativada'
      }`
    );
    yield setIsLoading(false);
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* logoutCustomerSaga(action) {
  const { payload } = action;

  try {
    yield setIsLoading(true);
    yield call(logoutCustomerRequest, payload);
    successAlert('O cliente foi deslogado');
    yield setIsLoading(false);
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* deactivateCustomerSaga(action) {
  const { payload } = action;
  try {
    yield setIsLoading(true);
    const response = yield call(deactivateCustomerResquest, payload);
    const metadata = response.data.meta;
    yield put(setCustomer({ customer: response.data.data, metadata }));
    successAlert('O cliente foi banido');
    yield setIsLoading(false);
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* activateCustomerSaga(action) {
  const { payload } = action;
  try {
    yield setIsLoading(true);
    const response = yield call(activateCustomerResquest, payload);
    const metadata = response.data.meta;
    yield put(setCustomer({ customer: response.data.data, metadata }));
    successAlert('O cliente foi reativado');
    yield setIsLoading(false);
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

export function* resetCustomerPasswordSaga(action) {
  try {
    yield call(resetPasswordRequest, action.payload);
    successAlert('O e-mail de redefinição de senha foi enviado.');
  } catch (err) {
    errorAlert(err);
  }
}

export function* exportCSVSaga(action) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(exportCSVCustomerResquest, action.payload);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${url.slice(27)}.csv`;
    document.body.appendChild(a);
    a.click();
    yield put(setIsLoading(false));
  } catch (err) {
    errorAlert(err);
  }
  return yield put(setIsLoading(false));
}

function* removeCustomerSaga(action) {
  const { payload } = action;
  try {
    yield setIsLoading(true);
    const response = yield call(removeCustomerRequest, payload);
    yield put(setCustomer({ customer: response.data.data }));
    successAlert('O cadastro do cliente foi excluído.');
    yield setIsLoading(false);
  } catch (err) {
    yield put(setIsLoading(false));
    errorAlert(err);
  }
}

function* customerSaga() {
  yield takeEvery(getCustomers, getCustomersSaga);
  yield takeEvery(goToCustomers, goToCustomerSaga);
  yield takeEvery(getEditCustomers, editCustomerSaga);
  yield takeEvery(
    getCustomerWalletTransactions,
    getCustomerWalletTransactionsSaga
  );
  yield takeEvery(editCustomerWallet, editCustomerWalletSaga);
  yield takeEvery(getCustomer, getCustomerSaga);
  yield takeEvery(getCustomerAddresses, getCustomerAddressesSaga);
  yield takeEvery(getCustomerCreditCards, getCustomerCreditCardsSaga);
  yield takeEvery(logoutCustomer, logoutCustomerSaga);
  yield takeEvery(deactivateCustomer, deactivateCustomerSaga);
  yield takeEvery(activateCustomer, activateCustomerSaga);
  yield takeEvery(resetCustomerPassword, resetCustomerPasswordSaga);
  yield takeEvery(exportCSV, exportCSVSaga);
  yield takeEvery(removeCustomer, removeCustomerSaga);
}

export default customerSaga;
