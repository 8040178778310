import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import 'moment/locale/pt-br';

import { Button, Drawer, Row, Form } from 'antd';
import filterIcon from '@/src/assets/images/filter-icon.svg';
import filterIconDisabled from '@/src/assets/images/filter-icon-disabled.svg';

import useProductsFilter from '@/src/hooks/useProductsFilter';
import { getOrders } from '@/src/store/modules/orders/slice';
import { getSubscriptions } from '@/src/store/modules/subscriptions/slice';
import { getPromotions } from '@/src/store/modules/promotions/slice';
import { getCustomers } from '@/src/store/modules/customers/slice';
import { getFilteredOrdersData } from '@/src/store/modules/expedition/slice';
import { getShippings } from '@/src/store/modules/shipping/slice';

import OrdersContent from './containers/Content/Orders';
import SubscriptionsContent from './containers/Content/Subscriptions';
import PromosContent from './containers/Content/Promos';
import CustomersContent from './containers/Content/Customers';
import ExpeditionContent from './containers/Content/Expedition';
import ProductsContent from './containers/Content/Products';
import EmbarksContent from './containers/Content/Embarks';

const FiltersMenu = props => {
  const { filterContent, disabled } = props;
  const { getProductsHandler } = useProductsFilter();

  const dispatch = useDispatch();

  const getOrdersHandler = useCallback(
    query => {
      dispatch(
        getOrders({
          ...query
        })
      );
    },
    [dispatch]
  );

  const getSubscriptionsHandler = useCallback(
    query => {
      dispatch(
        getSubscriptions({
          ...query
        })
      );
    },
    [dispatch]
  );

  const getPromotionsHandler = useCallback(
    query => {
      dispatch(
        getPromotions({
          ...query
        })
      );
    },
    [dispatch]
  );

  const getCustomersHandler = useCallback(
    query => {
      dispatch(
        getCustomers({
          ...query
        })
      );
    },
    [dispatch]
  );

  const getExpeditionHandler = useCallback(
    payload => {
      const filters = window.location.href.includes('expedicao?view=table')
        ? payload.query.filters.filter(item => {
            if (item.tag) {
              return item.key;
            }
            return item.key !== 'updated_at';
          })
        : payload.query.filters;
      dispatch(
        getFilteredOrdersData({
          query: {
            ...payload.query,
            filters
          }
        })
      );
    },
    [dispatch]
  );

  const getEmbarksHandler = useCallback(
    query => {
      dispatch(
        getShippings({
          ...query
        })
      );
    },
    [dispatch]
  );

  const ordersQuery = useSelector(state => state.orders.query);
  const subscriptionsQuery = useSelector(state => state.subscriptions.query);
  const promotionsQuery = useSelector(state => state.promotions.query);
  const customersQuery = useSelector(state => state.customers.query);
  const productsQuery = useSelector(state => state.allProducts.query);
  const expeditionQuery = useSelector(state => state.expedition.query);
  const embarksQuery = useSelector(state => state.shipping.query);

  const [isVisible, setIsVisible] = useState(false);
  const [productsQueryFilter, setProductsFilterQuery] = useState({});

  const onClose = () => {
    setIsVisible(false);
  };
  const showDrawer = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    setProductsFilterQuery(productsQuery);
  }, [productsQuery]);

  const [ordersFilters, setOrdersFilters] = useState({
    min_value: '',
    max_value: '',
    more_items_than: '',
    less_items_than: '',
    partially_delivered: false,
    with_open_issue: false,
    fraudulent: false,
    start_date: null,
    end_date: null,
    by_hub: [],
    by_status: [],
    by_deliverer: [],
    by_coupons: []
  });

  const [subscriptionsFilters, setSubscriptionsFilters] = useState({
    start_date: null,
    end_date: null,
    by_status: [],
    by_interval: 0,
    next_billing_start_date: null,
    next_billing_end_date: null
  });

  const [promotionsFilters, setPromotionsFilters] = useState({
    start_at: null,
    end_at: null,
    min_fixed_value: 0,
    max_fixed_value: 0,
    min_percentage_value: 0,
    max_percentage_value: 0,
    discount_type: null,
    active: false,
    expired: false,
    expiring: false,
    archived: false
  });

  const [customersFilters, setCustomersFilters] = useState({
    max_converted_value: '',
    min_converted_value: '',
    more_orders_than: '',
    less_orders_than: '',
    gender: '',
    start_date: null,
    end_date: null,
    banned: false,
    city_name: '',
    state_name: '',
    pet_month: null,
    pet_species: '',
    pet_gender: '',
    pet_breed: [],
    tags: []
  });

  const [expeditionFilters, setExpeditionFilters] = useState(
    window.ifoodOnly ? [{ key: 'sale_channel_id', type: 'eq', value: '2' }] : []
  );

  const [embarksFilters, setEmbarksFilters] = useState([]);

  const applyFilters = () => {
    const filtersRemovedEmpty = {};
    let filtersObj;
    switch (filterContent) {
      case 'orders':
        filtersObj = ordersFilters;
        break;
      case 'subscriptions':
        filtersObj = subscriptionsFilters;
        break;
      case 'promotions':
        filtersObj = promotionsFilters;
        break;
      case 'customer':
        filtersObj = customersFilters;
        break;
      case 'products':
        filtersObj = productsQueryFilter;
        break;
      // case 'embarks':
      //   filtersObj = embarksFilters;
      //   break;
      default:
        console.warn('Don`t match any filterContent');
    }
    if (filtersObj) {
      Object.keys(filtersObj).forEach(key => {
        if (filtersObj[key]) filtersRemovedEmpty[key] = filtersObj[key];
      });
    }
    const hasDateFilter =
      filtersRemovedEmpty.start_date || filtersRemovedEmpty.end_date;
    switch (filterContent) {
      case 'orders':
        getOrdersHandler({
          query: {
            page: 1,
            search: ordersQuery.search,
            sort: ordersQuery.sort,
            filters: filtersRemovedEmpty,
            daysAgo: hasDateFilter ? 'all' : ordersQuery.daysAgo
          }
        });
        break;
      case 'subscriptions':
        getSubscriptionsHandler({
          query: {
            page: 1,
            search: subscriptionsQuery.search,
            sort: subscriptionsQuery.sort,
            filters: filtersRemovedEmpty,
            daysAgo: hasDateFilter ? 'all' : subscriptionsQuery.daysAgo
          }
        });
        break;
      case 'promotions':
        getPromotionsHandler({
          query: {
            page: 1,
            search: promotionsQuery.search,
            filters: filtersRemovedEmpty
          }
        });
        break;
      case 'customer':
        getCustomersHandler({
          query: {
            page: 1,
            search: customersQuery.search,
            sort: customersQuery.sort,
            filters: filtersRemovedEmpty
          }
        });
        break;
      case 'expedition':
        getExpeditionHandler({
          query: {
            ...expeditionQuery,
            filters: expeditionFilters
          }
        });
        break;
      case 'embarks':
        getEmbarksHandler({
          query: {
            ...embarksQuery,
            filters: embarksFilters
          }
        });
        break;
      case 'products':
        getProductsHandler({
          query: {
            page: 1,
            search: filtersRemovedEmpty.search,
            filters: filtersRemovedEmpty.filters
          }
        });
        break;
      default:
        console.warn(filterContent, 'Don`t match any filter type');
    }
    setIsVisible(false);
  };

  const getContent = () => {
    switch (filterContent) {
      case 'orders':
        return (
          <OrdersContent
            ordersFilters={ordersFilters}
            setOrdersFilters={setOrdersFilters}
          />
        );
      case 'subscriptions':
        return (
          <SubscriptionsContent
            subscriptionsFilters={subscriptionsFilters}
            setSubscriptionsFilters={setSubscriptionsFilters}
          />
        );
      case 'promotions':
        return (
          <PromosContent
            promosFilters={promotionsFilters}
            setPromosFilters={setPromotionsFilters}
          />
        );
      case 'customer':
        return (
          <CustomersContent
            customersFilters={customersFilters}
            setCustomersFilters={setCustomersFilters}
          />
        );
      case 'products':
        return (
          <ProductsContent
            productsQueryFilter={productsQueryFilter}
            setProductsFilters={setProductsFilterQuery}
          />
        );
      case 'expedition':
        return (
          <ExpeditionContent
            expeditionFilters={expeditionFilters}
            setExpeditionFilters={setExpeditionFilters}
          />
        );
      case 'embarks':
        return (
          <EmbarksContent
            embarksFilters={embarksFilters}
            setEmbarksFilters={setEmbarksFilters}
          />
        );
      default:
        return (
          <OrdersContent
            ordersFilters={ordersFilters}
            setOrdersFilters={setOrdersFilters}
          />
        );
    }
  };

  return (
    <>
      <Button
        size="large"
        className="filter-component__button"
        onClick={showDrawer}
        disabled={disabled}
      >
        <Row gutter={8} type="flex" justify="center" align="middle">
          <img
            src={disabled ? filterIconDisabled : filterIcon}
            alt="Ícone de filtro"
          />
        </Row>
      </Button>
      <Drawer
        title={<h1>Filtros</h1>}
        placement="right"
        onClose={onClose}
        visible={isVisible}
        width={380}
        className={
          filterContent === 'subscriptions' ? 'subscription-drawer' : ''
        }
      >
        <Form>
          {getContent()}
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: '10'
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancelar
            </Button>
            <Button onClick={applyFilters} type="primary">
              Aplicar filtros
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

FiltersMenu.defaultProps = {
  disabled: false
};

FiltersMenu.propTypes = {
  filterContent: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default React.memo(FiltersMenu);
